import counterpart from 'counterpart';
import moment from 'moment';
import * as React from 'react';
import RTTranslate from 'react-translate-component';
import 'moment/locale/fi';
import 'moment/locale/en-gb';

export const Translate: React.FC = (props: any) => {
  return <RTTranslate content={props.children} />;
};

export class translateService {
  static translate(key: string, lang?: string): string {
    return counterpart(key, {
      fallback: '(EN) ' + counterpart(key, { locale: lang || 'en' }),
    });
  }

  static localize(date: string): string {
    return moment(date).format('l LT');
  }

  static localizeRange(startDate: string, endDate: string): string {
    return moment(startDate).format('l LT') + ' - ' + moment(endDate).format('l');
  }

  static fromNow(startDate: string): string {
    return moment(startDate).fromNow();
  }

  static localizeDuration(startDate: string, endDate: string): string {
    return moment.duration(moment(endDate).diff(moment(startDate))).humanize();
  }

  static setLocale(lang: string): void {
    counterpart.setLocale(lang);
    moment.locale(lang);
  }

  static registerTranslations(lang: string, langObject: any): void {
    counterpart.registerTranslations(lang, langObject);
  }
}
