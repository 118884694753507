import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const { pathname } = useLocation(); // Extracts pathname property(key) from an object

    useEffect(() => { // Automatically scrolls to top when pathname change detected
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export default ScrollToTop;