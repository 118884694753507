import React from 'react';
import * as serviceWorker from './serviceWorker';

const ServiceWorkerWrapper: React.FC = () => {


  React.useEffect(() => {
    const onSWUpdate = (registration: ServiceWorkerRegistration) => {
      console.log('New software found!');
      reloadPage(registration.waiting);
    };
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = (r: ServiceWorker | null) => {
    r?.postMessage({ type: 'SKIP_WAITING' });

    window.location.reload();
  };

  return <React.Fragment />;

}

export default ServiceWorkerWrapper;